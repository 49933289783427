@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
     hover:bg-rose-500 bg-gray-700 
    text-rose-400 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg;
  }

  .phone-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
     hover:bg-rose-500 bg-gray-700 
    text-rose-400 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg;
  }

  
  .dash-btn {
    @apply text-black
  }



  .canvas-icons {
    @apply
      relative
      flex
      items-center
      justify-center
      h-10 w-10
      mt-1 mb-1
      mx-2
      bg-gray-400
      hover:bg-rose-500
      text-rose-400
      hover:text-white
      rounded-3xl
      hover:rounded-xl
      transition-all
      duration-300
      ease-linear
      cursor-pointer
      shadow-lg;
  }

  .settings-icon{ 
    @apply relative flex items-center justify-center 
    h-8 w-8 mb-2 mx-auto ml-3 text-rose-400
    cursor-pointer 
  }
  
  .upload-icon {
    @apply text-rose-500;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold  z-50
    transition-all duration-100 scale-0 origin-left;
  }
  
  .canvas-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max bottom-10 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold 
    transition-all duration-100 scale-0 origin-bottom;
  }

  .canvas-icons:hover .canvas-tooltip {
    @apply scale-100;
  }
  
  .sidebar-hr {
    @apply bg-gray-800 
    border border-gray-800 rounded-full
    mx-2;
  }

  .top-navigation {
    @apply flex flex-row items-center justify-evenly 
    bg-gray-900 
    w-full h-16 
    m-0 
    shadow-lg;
  }

  .content-container-icons {
    @apply transition duration-300 ease-in-out 
    hover:text-rose-500 
    cursor-pointer;
  }

  .top-navigation-icon {
    @apply text-gray-500
    mr-3 ml-4
    transition duration-300 ease-in-out 
    hover:text-rose-500 
    cursor-pointer;
  }

  .title-hashtag {
    @apply text-lg tracking-wider font-semibold text-gray-500 
    ml-2 my-auto;
  }

  .title-text {
    @apply text-xl text-gray-500 tracking-wider font-semibold text-opacity-80 
    mr-auto ml-2 my-auto 
    transition duration-300 ease-in-out;
  }

  .content-container {
    @apply flex-row bg-gray-700 h-full w-full overflow-hidden;
  }

  .canvas-content-container {
    @apply flex-row bg-gray-700 h-screen w-full overflow-x-hidden overflow-y-auto ;
  }

  
  
  .grid-container {
    @apply flex-grow h-full w-full; /* Make the grid container grow to fill available space */
  }

  .content-list {
    @apply flex-row mt-0 pb-3 sm:pb-12;
    @apply sm:ml-4; /* Add smaller margin for mobile */
    @apply lg:ml-16; /* Maintain larger margin for large screens */
    @apply sm:pb-4; /* Reduce padding for mobile */
  }

  .grid {
    @apply w-full;
  }
}

#my-canvas {
  @apply w-full h-full p-2;
}

.canvas-side-elements {
  @apply text-center rounded-lg border border-solid border-black p-4 mb-4 h-1/3 overflow-x-hidden;
}

.layers {
  @apply text-center rounded-lg border border-solid border-black p-4 h-1/4 overflow-x-hidden;
}

.canvas-bottom-element {
  @apply bg-slate-100 text-center rounded-lg border flex justify-between items-center w-full border-solid border-black;
}

.canvas-tooltip {
  @apply absolute w-auto p-2 m-2 min-w-max bottom-8 rounded-md shadow-md
  text-white bg-gray-900 
  text-xs font-bold 
  transition-all duration-100 scale-0 origin-bottom;
}

.canvas-icons:hover .canvas-tooltip {
  @apply scale-100;
}

@media (min-width: 640px) {
  .content-container {
    @apply flex-row;
  }
}

/* .top-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #282c34;
} */

/* .icon {
  color: white;
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
} */

.title {
  color: white;
  font-size: 18px;
  flex-grow: 1;
}

button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;  /* Ensures the modal appears above other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

/* Close button for modal */
.close-button {
  position: absolute;
  top: 16px; /* Adjusted top position for better alignment */
  right: 28px; /* Adjusted right position for better alignment */
  color: #9CA3AF; /* Equivalent to text-gray-400 in Tailwind */
  background-color: transparent;
  border: none;
  font-size: 16px; /* Increased font size for better visibility */
  cursor: pointer;
  z-index: 10; /* Added z-index to ensure button stays on top */
  transition: color 0.2s ease; /* Smooth transition for color change */
}

.close-button:hover {
  color: #4B5563; /* Equivalent to hover:text-gray-600 in Tailwind */
}

@media (max-width: 768px) {
  /* Hide the sidebar on mobile */
  .sidebar {
    display: none;
  }
}

@media (max-width: 768px) {
  /* Hide extra icons on desktop */
  .top-navigation .hidden {
    display: flex !important;
  }
  
  /* Top navigation styling for mobile */
  .top-navigation {
    padding: 0.5rem 1rem;
    justify-content: space-between;
  }
  
  .top-navigation-icon {
    margin: 0 0.5rem;
    cursor: pointer;
  }
}
.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #f43f5e;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* skeleton.css */
.skeleton {
  /* background-color: red  */
  background: linear-gradient(-90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

